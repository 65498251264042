<template>
  <el-dialog
    :title="'打印物料信息'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px"
    @close="close()"
  >
  <div>
      <el-form class="number_of_repeats">
        <el-form-item label="每个物料重复打印" class="number_of_repeats_1">
          <el-select v-model="frequency" placeholder="请选择......">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="8" value="8"></el-option>
            <el-option label="16" value="16"></el-option>
          </el-select>
        </el-form-item>
        <span class="span_st">次</span>
      </el-form>

    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="exportPDF()"
        size="small"
        :disabled="dataLoading"
        >打印</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { infoList ,initiateExportMaterialQRCodeInBulk} from "@/api/modules/material";
import VueQr from "vue-qr";
import fileSaver from 'file-saver'
export default {
  data() {
    return {
      frequency:'1',
      visible: false,
      dataLoading: false,
      showBtn: false,
      dataForm: {
        ids: [],
        materialNo: "",
        materialName: "",
        qrUrl: "",
      },
      materialList: {},
    };
  },
  components: {
  },
  methods: {
    close() {
      this.visible = false;
    },
    init(ids) {
      this.dataForm.ids = ids;
      this.visible = true;
      this.$nextTick(() => {
        this.dataForm.materialNo = "";
        this.dataForm.materialName = "";
        this.dataForm.qrUrl = "";
        if (this.dataForm.ids) {
          infoList(ids).then(({ data }) => {
            if (data && data.code === 0) {
              this.materialList = data.materialList;
            }
          });
        }
      });
    },
    exportPDF(){
      let params = {
        frequency : this.frequency,
        materielIdList : this.dataForm.ids
      }
      initiateExportMaterialQRCodeInBulk(params).then((res)=>{
        var fileName = '材料标签二维码'+ '-'+Math.floor(new Date().getTime() / 1000)
         fileSaver.saveAs(res.data,fileName)
      })
      this.close()
      this.frequency ='1'
    },
    printSub() {
      //this.$print(this.$refs.print);
      //var oldHtml = document.body.innerHTML;
      document.body.innerHTML = document.getElementById("printarea").innerHTML;
      setTimeout(() => {
        window.print();
        setTimeout("window.close();", 0);
        window.location.reload();
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.productDirs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  .left {
    margin-left: 30px;
  }
  .right {
    margin-left: 5px;
    margin-right: 30px;
  }
}
.fontNormal {
  font-weight: normal;
}

.number_of_repeats {
  position: relative;
}

.span_st {
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100px;
}
</style>
